import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./../contexts/contextProvider";
import * as mod from "./../../utils/url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AuthStyle.css";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";

const Login = () => {
  const { setAuth, auth } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${mod.api_url}/api/v1/admin/login/admin`, {
        email,
        password,
      });

      if (res.data.success) {
        if (res.data.admin.role === "Admin") {
          toast.success(res.data.message);
          setAuth({
            ...auth,
            admin: res.data.admin,
            token: res.data.token,
          });
          localStorage.setItem("admin", JSON.stringify(res.data));
          navigate("/dashboard");
          window.location.reload();
        } else {
          toast.success(res.data.message);
        }
      } else {
        toast.error(res.data.message || "Login failed");
      }
    } catch (error) {
      console.error(error);
      toast.error("Id or Password is Invailid");
    }
  };

  return (
    <>
      <div style={{ background: "black", height: "100vh" }}>
        <ToastContainer />
        <Container centerContent>
          <VStack spacing="5px" color="black">
            <Box
              width="100%"
              height="auto"
              border="1px solid white"
              padding={5}
              borderRadius="10px"
              marginTop="50px"
            >
              <Text color="white" textAlign="center" fontSize="xx-large">
                Admin Panel For Manage All Data
              </Text>
              <Text
                background="green"
                width="auto"
                textAlign="center"
                color="white"
                fontSize="x-large"
                borderRadius="30px"
              >
                Login Form
              </Text>
              <FormControl id="email" isRequired>
                <FormLabel color="white">Enter Email</FormLabel>
                <Input
                  color="black"
                  type="text"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  id="phone-input"
                  label="mobile"
                  variant="outlined"
                  placeholder="Enter your Email "
                  required
                />
              </FormControl>

              <FormControl id="password" isRequired>
                <FormLabel color="white">Enter Password</FormLabel>
                <Input
                  color="black"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-control"
                  id="password-input"
                  label="Password"
                  variant="outlined"
                  placeholder="Enter Your Password"
                  required
                />
              </FormControl>
              <Button
                colorScheme="blue"
                mt={2}
                width="100%"
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Box>
          </VStack>
        </Container>
      </div>
    </>
  );
};

export default Login;
