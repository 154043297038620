import React, { useState } from "react";
import "./Homepage.css";
import "react-toastify/dist/ReactToastify.css";
import { Link, NavLink } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import { useNavigate } from "react-router-dom";
import Navbar from "../pages/Admin/Navbar";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { BsReverseLayoutSidebarInsetReverse } from "react-icons/bs";

const { Header, Sider, Content } = Layout;

function Homepage() {
  const [collapsed, setCollapsed] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();

  return (
    <>
      <div className="for_all_page">
        <div style={{ position: "fixed", width: "100%", zIndex: 1000 }}>
          <Navbar style={{ background: "white" }} />
          <Button
            ref={btnRef}
            colorScheme="teal"
            onClick={onOpen}
            marginLeft={4}
            marginTop={2}
          >
            <BsReverseLayoutSidebarInsetReverse />
          </Button>
        </div>
        <Layout className="container_contin">
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Admin Dashboard</DrawerHeader>
              <DrawerBody>
                <Link to="/dashboard">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    Dashboard
                  </Button>
                </Link>
                <Link to="/createCategory">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    Courses
                  </Button>
                </Link>
                <Link to="/createProduct">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    Add Product
                  </Button>
                </Link>

                <Link to="/products">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    Products
                  </Button>
                </Link>

                <Link to="/adminOrders">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    My Orders
                  </Button>
                </Link>

                <Link to="/slider">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    Slider
                  </Button>
                </Link>

                <Link to="/multisliders">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    Multi Sliders
                  </Button>
                </Link>

                <Link to="/users">
                  <Button width="100%" marginBottom={2} colorScheme="green">
                    Users
                  </Button>
                </Link>
              </DrawerBody>

              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Close
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </Layout>
      </div>
    </>
  );
}

export default Homepage;
