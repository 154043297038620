import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import toast from "react-hot-toast";
import axios from "axios";
import CategoryForm from "./CategoryForm";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as mod from "../../utils/url";
import "../AdminCss/CreateCategory.css";
import Homepage from "../../components/Homepage";
import { Table } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Input,
  Text,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const CreateCategory = () => {
  const [course, setcourse] = useState([]);
  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updatedName, setUpdatedName] = useState("");
  const [updatedPhoto, setUpdatedPhoto] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [viewType, setViewType] = useState("list");
  const { isOpen, onOpen, onClose } = useDisclosure();
  // console.log(selected, 'selected')
  const handleToggleView = () => {
    setViewType((prevType) => (prevType === "list" ? "card" : "list"));
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const existingCategory = course.find((item) => item.name === name);

      if (existingCategory) {
        toast.error("course already exists");
      } else {
        const formData = new FormData();
        formData.append("photo", photo);
        formData.append("name", name);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await axios.post(
          `${mod.api_url}/api/v1/course/create-course`,
          formData,
          config
        );

        if (data?.success) {
          toast.success(`${name} is created`);
          // window.location.reload();
          getAllCategory();
        } else {
          toast.error(data.message || "Failed to create course");
        }
      }
    } catch (error) {
      console.error(error);

      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized. Please log in.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      if (data.success) {
        setcourse(data.course);
        setPhoto(null);
        setName("");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting course");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    onClose();

    try {
      const updatedFormData = new FormData();
      updatedFormData.append("name", updatedName);
      if (updatedPhoto) {
        updatedFormData.append("photo", updatedPhoto);
      }

      const response = await axios.put(
        `${mod.api_url}/api/v1/course/update-course/${selected._id}`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;

      if (data.success) {
        toast({
          title: "Course create Sucessfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setSelected(null);
        setUpdatedName("");
        setUpdatedPhoto("");
        setVisible(false);
        getAllCategory();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Response error:", error.response.data);
        toast({
          title: "Failed to update course. Please try again.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast({
          title: "No response received from the server.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        console.error("Request setup error:", error.message);
        toast.error("Error setting up the request.");
        toast({
          title: "Error setting up the request..",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `${mod.api_url}/api/v1/course/delete-course/${id}`
      );

      if (data.success) {
        toast.success(data.message);
        getAllCategory();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("course not deleted");
    }
  };

  return (
    <>
      <Box width="100%">
        <Homepage />
        <Center>
          <div className="col-md-11" style={{ marginTop: 110 }}>
            <h1 className="text-center">Create Course Category </h1>
            <div className="p-3 w-100">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    type="file"
                    name="photo"
                    onChange={(e) => setPhoto(e.target.files[0])}
                    accept=".jpg, .jpeg, .png"
                    id="outlined-basic"
                    variant="outlined"
                    border="1px solid black"
                  />
                  {photo && (
                    <div className="text-center">
                      <Image
                        src={URL.createObjectURL(photo)}
                        alt="Product photo"
                        width="90px"
                        height="70px"
                        className="img img-responsive"
                      />
                    </div>
                  )}
                  <Text>{photo ? photo.name : ""}</Text>
                </div>
                <div className="col-md-6">
                  <CategoryForm
                    handleSubmit={handleSubmit}
                    value={name}
                    setValue={setName}
                  />
                </div>
              </div>
            </div>
            <div>
              {viewType === "list" ? (
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className="table-course"
                >
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {course?.map((c) => (
                      <tr key={c._id}>
                        <td>
                          <Image
                            src={`${mod.api_url}/api/v1/course/singlePhoto-course/${c._id}`}
                            alt="Uploaded"
                            className="card-img-top"
                            width="80px"
                            height="70px"
                          />
                        </td>
                        <td>{c.name}</td>
                        <td>
                          <div className="btn-actions">
                            <Button
                              width="48%"
                              colorScheme="blue"
                              marginRight={1}
                              onClick={() => {
                                setVisible(true);
                                setUpdatedName(c.name);
                                setSelected(c);
                                onOpen();
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              width="48%"
                              colorScheme="red"
                              onClick={() => {
                                confirmAlert({
                                  title: "Confirm yes or no",
                                  message: "Are you sure to delete this.",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => handleDelete(c._id),
                                    },
                                    {
                                      label: "No",
                                    },
                                  ],
                                });
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            onCancel={() => setVisible(false)}
            footer={null}
            visible={visible}
          >
            <ModalContent>
              <ModalHeader textAlign="center">Edit Your course</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <label
                  className=""
                  style={{ width: "100%", marginBottom: "15px" }}
                >
                  {updatedPhoto ? updatedPhoto.name : ""}
                  <Input
                    type="file"
                    name="photo"
                    onChange={(e) => setUpdatedPhoto(e.target.files[0])}
                    accept=".jpg, .jpeg, .png"
                    id="outlined-basic"
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </label>
                {updatedPhoto && (
                  <div className="text-center">
                    <Image
                      src={URL.createObjectURL(updatedPhoto)}
                      alt="Product photo"
                      height={"200px"}
                      className="img img-responsive"
                    />
                  </div>
                )}

                <CategoryForm
                  value={updatedName}
                  setValue={setUpdatedName}
                  handleSubmit={handleUpdate}
                />
              </ModalBody>
              <ModalFooter>
                <Button colorScheme="red" mr={3} onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Center>
      </Box>
    </>
  );
};

export default CreateCategory;
