import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import * as mod from "../../utils/url";
import "../../pages/AdminCss/UpdateProduct.css";
import Homepage from "../../components/Homepage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Center, Input } from "@chakra-ui/react";

const userData = JSON.parse(localStorage.getItem("admin")) || {};
const Admin = userData?.admin?.id;
const { Option } = Select;

const UpdateProduct = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [currency, setCurrency] = useState("");
  const [price, setPrice] = useState("");
  const [offers, setOffers] = useState("");
  const [id, setId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImagePreviews, setSelectedImagePreviews] = useState([]);
  const [offerDate, setOfferDate] = useState(null);
  const [rating, setRating] = useState(0);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");

  useEffect(() => {
    getSingleProduct();
  }, []);

  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/product/get-product/${params.slug}`
      );
      console.log(data); // Log the data to see the response structure
      if (data.product) {
        setName(data.product.name || "");
        setId(data.product._id || "");
        setDescription(data.product.description || "");
        setPrice(data.product.price || "");
        setCurrency(data.product.currency || "");
        setOffers(data.product.offers || "");
        setSelectedCourse(data.product.courses?._id || "");
        setRating(data.product.rating || 0);
        setOfferDate(
          data.product.offerDate ? new Date(data.product.offerDate) : null
        ); // Ensure proper date conversion
        setSelectedFiles(data.product.images || []);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to load product details");
    }
  };

  // Get all courses
  const getAllCourses = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      if (data?.success) {
        setCourses(data.course);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to load courses");
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  // Update product function
  const handleUpdate = async (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Confirm update",
      message: "Are you sure you want to update this product?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const productData = new FormData();
              productData.append("name", name);
              productData.append("Admin", Admin);
              productData.append("description", description);
              productData.append("price", price);
              productData.append("currency", currency);
              productData.append("offers", offers);
              productData.append("courses", selectedCourse);
              productData.append("ratting", rating);
              productData.append("offerDate", offerDate);
              for (let i = 0; i < selectedFiles.length; i++) {
                productData.append("images", selectedFiles[i]);
              }
              const { data } = await axios.put(
                `${mod.api_url}/api/v1/product/update-product/${id}`,
                productData,
                { new: true }
              );
              if (data?.success) {
                toast.error(data?.message);
              } else {
                toast.success("Product Updated Successfully");
                navigate("/products");
              }
            } catch (error) {
              console.error(error);
              toast.error("Something went wrong");
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleFileChange = (e) => {
    const selectedImages = e.target.files;
    setSelectedFiles([...selectedFiles, ...selectedImages]);

    // Generate previews for the selected images
    const previews = Array.from(selectedImages).map((image) =>
      URL.createObjectURL(image)
    );
    setSelectedImagePreviews([...selectedImagePreviews, ...previews]);
  };

  // Delete preview image
  const handleDeleteImage = (index) => {
    const updatedFiles = [...selectedFiles];
    const updatedPreviews = [...selectedImagePreviews];
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);

    // Update the state with the new arrays
    setSelectedFiles(updatedFiles);
    setSelectedImagePreviews(updatedPreviews);
  };

  // Delete a product
  const handleDelete = async () => {
    try {
      let answer = window.prompt("Are You Sure want to delete this product?");
      if (!answer) return;
      const { data } = await axios.delete(
        `${mod.api_url}/api/v1/product/delete-product/${id}`
      );
      toast.success("Product Deleted Successfully");
      navigate("/dashboard/admin/products");
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <Box width="100%">
        <Homepage />
        <Center>
          <div className="row">
            <div className="col-md-12" style={{ marginTop: 100 }}>
              <h1 className="text-center" style={{ backgroundColor: "white" }}>
                Update Product
              </h1>

              <div className="m-1 w-100">
                <div className="row">
                  <div className="col-md-6">
                    <label>Course</label>
                    <Select
                      bordered={false}
                      placeholder="Select a category"
                      size="large"
                      className="form-select mb-3"
                      onChange={(value) => setSelectedCourse(value)}
                      value={selectedCourse}
                    >
                      {courses &&
                        courses?.map((c) => (
                          <Option key={c._id} value={c._id}>
                            {c.name}
                          </Option>
                        ))}
                    </Select>
                  </div>

                  <div className="col-md-6">
                    <label>Product image</label>

                    <div className="btn btn-outline-secondary col-md-12">
                      <Input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        id="outlined-basic"
                        variant="outlined"
                        onChange={handleFileChange}
                        multiple
                      />
                    </div>
                  </div>
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={file}
                          style={{
                            width: "70px",
                            height: "70px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                    ))}
                  </ul>
                </div>
                <div className="mb-3"></div>
                {selectedImagePreviews.length > 0 && (
                  <div className="mb-3">
                    <h5>Selected Images:</h5>
                    {selectedImagePreviews.map((preview, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={preview}
                          alt={`Selected ${index + 1}`}
                          style={{
                            width: "100px",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        />
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() => handleDeleteImage(index)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                )}
                <div className="row">
                  <div className="col-6">
                    <label>Product name</label>
                    <Input
                      type="text"
                      value={name}
                      placeholder="Write a name"
                      className="form-control"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-6">
                    <label>% Off price</label>
                    <Input
                      type="number"
                      value={offers}
                      placeholder="% Write your offers"
                      className="form-control"
                      onChange={(e) => setOffers(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label>Description</label>
                  <textarea
                    type="text"
                    value={description}
                    placeholder="Write a description"
                    className="form-control"
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ height: 200 }}
                  />
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <label>Product Price</label>
                      <Input
                        type="number"
                        value={price}
                        placeholder="Write a price"
                        className="form-control"
                        onChange={(e) => setPrice(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <label>Currency</label>
                    <select
                      className="mb-3 form-control"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                      required
                    >
                      <option value="$">USD</option>
                      <option value="€">Euro</option>
                      <option value="£">GBP</option>
                      <option value="₹">INR</option>
                      <option value="AED">AED</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <label>Offer Valid Until</label>
                    <br />
                    <DatePicker
                      selected={offerDate}
                      onChange={(date) => setOfferDate(date)}
                      className="mb-3 form-control"
                      placeholderText="Select Offer Date"
                      dateFormat="MMMM d, yyyy"
                      isClearable
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={5}
                      width="100%"
                    />
                  </div>
                  <div className="col-6">
                    <label>Rating</label>
                    <select
                      className="mb-3 form-control"
                      value={rating}
                      onChange={(e) => setRating(e.target.value)}
                      placeholder="Product Rating"
                      required
                    >
                      <option value="">Product Rating</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="mb-3">
                      <Button
                        colorScheme="green"
                        width="100%"
                        onClick={handleUpdate}
                      >
                        UPDATE PRODUCT
                      </Button>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <Button
                        colorScheme="red"
                        width="100%"
                        onClick={handleDelete}
                      >
                        DELETE PRODUCT
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Center>
      </Box>
    </>
  );
};

export default UpdateProduct;
