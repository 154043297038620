import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./components/contexts/contextProvider";
import { OrderProvider } from "./components/contexts/orderContext";
import { ChakraProvider } from "@chakra-ui/react";
import * as React from "react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider>
    <React.StrictMode>
      <AuthProvider>
        <OrderProvider>
          <App />
        </OrderProvider>
      </AuthProvider>
    </React.StrictMode>
  </ChakraProvider>
);
reportWebVitals();
