import React from "react";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";

const Layout = ({ children, title, description, keywords, author }) => {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta name="author" content={author} />
          <title>{title}</title>
        </Helmet>
        <main style={{ minHeight: "100vh", minWidth: "85vw" }}>
          <Toaster />
          {children}
        </main>
      </div>
    </>
  );
};

Layout.defaultProps = {
  title: "Valuedpropvt",
  description: "mern stack project",
  keywords: "mern,react,node,mongodb",
  author: "sk",
};

export default Layout;
