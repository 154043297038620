import React, { useState, useEffect } from "react";
import "../../pages/AdminCss/CreateProduct.css";
import axios from "axios";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import * as mod from "../../utils/url";
import Homepage from "../../components/Homepage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Center, Input } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

const { Option } = Select;

const CreateProduct = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("$");
  const [course, setCourse] = useState("");
  const [offers, setOffers] = useState("");
  const [offerDate, setOfferDate] = useState(null);
  const [rating, setRating] = useState(0);
  const [pdf, setPdf] = useState(null);
  const [image, setImage] = useState(null);
  const toast = useToast();

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "pdf") {
      setPdf(files[0]);
    } else if (name === "image") {
      setImage(files[0]);
    }
  };

  const getAllCourses = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/v1/course/get-course`
      );
      if (data?.success) {
        setCourses(data?.course);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to load courses");
    }
  };

  useEffect(() => {
    getAllCourses();
  }, []);

  const handleCreate = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !description ||
      !price ||
      !course ||
      !currency ||
      !pdf ||
      !image
    ) {
      toast({
        title: "Please fill in all fields.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("currency", currency);
      formData.append("offers", offers);
      formData.append("course", course);
      formData.append("rating", rating);
      formData.append("offerDate", offerDate);
      formData.append("pdf", pdf);
      formData.append("image", image);

      const { data } = await axios.post(
        `${mod.api_url}/api/v1/product/create-product`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (data?.success) {
        toast({
          title: "Course create Sucessfully.",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        setTimeout(() => {
          navigate("/products");
        }, 1000);
        setName("");
        setDescription("");
        setPrice("");
        setCurrency("");
        setOffers("");
        setCourse("");
        setRating("");
        setOfferDate("");
        setPdf("");
        setImage("");
      } else {
        toast({
          title: "Failed to create product.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Failed to create product.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Homepage />
      <Center>
        <div className="col-md-10" style={{ marginTop: 50 }}>
          <h1>Create Product</h1>
          <form onSubmit={handleCreate}>
            <div className="row">
              <div className="col-md-12">
                <label>Course</label>
                <Select
                  bordered={false}
                  placeholder="Select a course"
                  size="large"
                  className="form-select mb-3"
                  onChange={setCourse}
                  value={course}
                >
                  {courses.map((c) => (
                    <Option
                      key={c._id}
                      value={c._id}
                      placeholder="Select a Course"
                    >
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <br />
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>PDF File</label>
                <br />
                <Input
                  type="file"
                  name="pdf"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  required
                />
              </div>
              <div className="col-md-6">
                <label>Product Image</label>
                <br />
                <Input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleFileChange}
                  required
                />
              </div>
            </div>

            <div className="mb-3">
              <label>Product Name</label>
              <Input
                type="text"
                value={name}
                placeholder="Enter product name"
                className="form-control"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label>Price</label>
              <Input
                type="number"
                value={price}
                placeholder="Enter price"
                className="form-control"
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label>Currency</label>
              <select
                className="form-control"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                required
              >
                <option value="$">USD</option>
                <option value="€">Euro</option>
                <option value="£">GBP</option>
                <option value="₹">INR</option>
                <option value="AED">AED</option>
              </select>
            </div>
            <div className="mb-3">
              <label>Offers (%)</label>
              <Input
                type="number"
                value={offers}
                placeholder="Enter offers"
                className="form-control"
                onChange={(e) => setOffers(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label>Description</label>
              <textarea
                value={description}
                placeholder="Enter description"
                className="form-control"
                onChange={(e) => setDescription(e.target.value)}
                style={{ height: 150 }}
                required
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <label>Offer Valid Until</label>
                <br />
                <DatePicker
                  selected={offerDate}
                  onChange={setOfferDate}
                  className="form-control"
                  placeholderText="Select offer date"
                  dateFormat="MMMM d, yyyy"
                  isClearable
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={5}
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-6">
                <label>Product Rating</label>
                <select
                  className="form-control"
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option value="0">Select Rating</option>
                  {[1, 2, 3, 4, 5].map((r) => (
                    <option key={r} value={r}>
                      {r} Star
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-4">
              <Button type="submit" marginBottom={3}>
                Create Product
              </Button>
            </div>
          </form>
        </div>
      </Center>
    </Box>
  );
};

export default CreateProduct;
