import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import "../AdminCss/AdminDashboard.css";
import { useAuth } from "../../components/contexts/contextProvider";
import axios from "axios";
import * as mod from "../../utils/url";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { Progress } from "antd";
import { Tooltip, Space } from "antd";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts";
import { Table } from "react-bootstrap";
import { Select, Modal } from "antd";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { IoIosPrint } from "react-icons/io";
import { MdPreview } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { FcPrevious } from "react-icons/fc";
import { FcNext } from "react-icons/fc";

const userData = JSON.parse(localStorage.getItem("admin")) || {};
const Admin = userData?.admin;
const AdminDashboard = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const ordersPerPage = 5;

  const { Option } = Select;
  const [itemOffset, setItemOffset] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const chartSetting = {
    yAxis: [
      {
        label: "rainfall (mm)",
      },
    ],
    width: 500,
    height: 300,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-20px, 0)",
      },
    },
  };

  const dataset = [
    {
      london: 59,
      paris: 57,
      newYork: 86,
      seoul: 21,
      month: "Jan",
    },
    {
      london: 50,
      paris: 52,
      newYork: 78,
      seoul: 28,
      month: "Fev",
    },
    {
      london: 47,
      paris: 53,
      newYork: 106,
      seoul: 41,
      month: "Mar",
    },
    {
      london: 54,
      paris: 56,
      newYork: 92,
      seoul: 73,
      month: "Apr",
    },
    {
      london: 57,
      paris: 69,
      newYork: 92,
      seoul: 99,
      month: "May",
    },
    {
      london: 60,
      paris: 63,
      newYork: 103,
      seoul: 144,
      month: "June",
    },
    {
      london: 59,
      paris: 60,
      newYork: 105,
      seoul: 319,
      month: "July",
    },
    {
      london: 65,
      paris: 60,
      newYork: 106,
      seoul: 249,
      month: "Aug",
    },
    {
      london: 51,
      paris: 51,
      newYork: 95,
      seoul: 131,
      month: "Sept",
    },
    {
      london: 60,
      paris: 65,
      newYork: 97,
      seoul: 55,
      month: "Oct",
    },
    {
      london: 67,
      paris: 64,
      newYork: 76,
      seoul: 48,
      month: "Nov",
    },
    {
      london: 61,
      paris: 70,
      newYork: 103,
      seoul: 25,
      month: "Dec",
    },
  ];

  const valueFormatter = (value) => `${value}mm`;

  useEffect(() => {
    if (auth?.token) {
      console.log("Setting up interval...");
      // Fetch orders initially

      // Clean up the interval on component unmount
      return () => {
        // clearInterval(intervalId);
        // console.log("Interval cleared.");
      };
    }
  }, [auth?.token]);

  return (
    <div className="for_all_page">
      <Layout>
        <h1 className="text-center" style={{ marginLeft: "30px" }}>
          Dashboard
        </h1>
        <div className="container dashboard">
          <div className="row">
            {/* First Pair of Cards */}
            <div className="col-md-5">
              <div className="card p-3 admin_data" style={{ width: "100%" }}>
                <h1
                  className="text-center"
                  style={{ background: "whitesmoke" }}
                >
                  Admin Profile
                </h1>
                <img className="admin_profile" src="images.jpg" alt="" />
                <h6 className="data">Admin Name: {Admin.name}</h6>
                <h6 className="data">Admin Email: {Admin.email}</h6>
                <h6 className="data">Admin Contact: {Admin?.mobile}</h6>
                <h6 className="data">Admin GSTIN: {Admin?.gstIn}</h6>
                <h6 className="data">Admin PAN: {Admin?.pan}</h6>
              </div>
              <span style={{ width: "50%", padding: "20px" }}>
                <BarChart
                  dataset={dataset}
                  xAxis={[{ scaleType: "band", dataKey: "month" }]}
                  series={[
                    { dataKey: "london", label: "London", valueFormatter },
                    { dataKey: "paris", label: "Paris", valueFormatter },
                    { dataKey: "newYork", label: "New York", valueFormatter },
                    { dataKey: "seoul", label: "Seoul", valueFormatter },
                  ]}
                  {...chartSetting}
                />
              </span>
            </div>

            <div className="col-md-7">
              <div className="card p-3 admin_data6" style={{ width: "100%" }}>
                <h1
                  className="text-center"
                  style={{ background: "whitesmoke" }}
                >
                  Total Account
                </h1>
                {/* Content for the second card in the first pair */}
                <div className="col-md-6" style={{ width: "100%" }}>
                  <div
                    className="card p-3 admin_data5"
                    style={{ width: "100%" }}
                  >
                    {/* Small Cards for E-commerce Metrics */}
                    <div className="row mt-3 small-row">
                      <div className="col-md-6">
                        <div
                          className="card p-2 text-center"
                          style={{ width: "100%" }}
                        >
                          <h6 style={{ width: "100%" }}>Total Sales</h6>
                          {/* Display total sales value here */}
                          <Row gutter={16}>
                            <Col span={12}>
                              <Card bordered={false}>
                                <Statistic
                                  title="Active"
                                  value={11.28}
                                  precision={2}
                                  valueStyle={{ color: "#3f8600" }}
                                  prefix={<ArrowUpOutlined />}
                                  suffix="%"
                                />
                              </Card>
                            </Col>
                            <Col span={12}>
                              <Card bordered={false}>
                                <Statistic
                                  title="Idle"
                                  value={9.3}
                                  precision={2}
                                  valueStyle={{ color: "#cf1322" }}
                                  prefix={<ArrowDownOutlined />}
                                  suffix="%"
                                />
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="card p-2 text-center"
                          style={{ width: "100%" }}
                        >
                          <h6>Total Income</h6>
                          {/* Display total income value here */}
                          <Row gutter={16}>
                            <Col span={12} style={{ height: 193 }}>
                              <Statistic
                                title="Account Balance"
                                value={112893}
                                precision={2}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="card p-2 text-center"
                          style={{ width: "100%" }}
                        >
                          <h6>Total Users</h6>
                          {/* Display total users value here */}
                          <Progress percent={30} />
                          <Progress percent={50} status="active" />
                          <Progress percent={70} status="exception" />
                          <Progress percent={100} />
                          <Progress percent={50} showInfo={false} />
                        </div>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div
                          className="card p-2 text-center"
                          style={{ width: "100%" }}
                        >
                          <h6>Total Orders</h6>
                          <Tooltip title="3 done / 3 in progress / 4 to do">
                            <Progress percent={60} success={{ percent: 30 }} />
                          </Tooltip>
                          <Space wrap>
                            <Tooltip title="3 done / 3 in progress / 4 to do">
                              <Progress
                                percent={60}
                                success={{ percent: 30 }}
                                type="circle"
                              />
                            </Tooltip>
                          </Space>
                          {/* Display total orders value here */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* Modal for Bill Preview */}
    </div>
  );
};

export default AdminDashboard;
