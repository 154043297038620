import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import Layout from "../Admin/Layout";
import "./../AdminCss/slider.css";
import * as mod from "../../utils/url";
import { MdDeleteForever } from "react-icons/md";
import "../../pages/AdminCss/slider.css";
import Homepage from "../../components/Homepage";
import { Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Include Bootstrap CSS
import { confirmAlert } from "react-confirm-alert"; // Import
import { Button, Center, Input, Text } from "@chakra-ui/react";

const MultiSlider = () => {
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState(null);
  const [des, setDes] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [uploadedData, setUploadedData] = useState([]);

  const handleUpload = async (e) => {
    e.preventDefault();

    if (title !== "" && des !== "" && subTitle !== "") {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("title", title);
        formDataToSend.append("des", des);
        formDataToSend.append("photo", photo);
        formDataToSend.append("subTitle", subTitle);

        const { data } = await axios.post(
          `${mod.api_url}/api/vi/multiple/multiple-upload`,
          formDataToSend
        );

        if (data?.success) {
          toast.success(data?.message);
          setTitle("");
          setDes("");
          setSubTitle("");
          setPhoto("");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred while uploading.");
      }
    } else {
      toast.error("Please fill in all fields and select at least one file.");
    }
  };
  //deleteSliderImage
  const getAllImg = async () => {
    try {
      const response = await axios.get(
        `${mod.api_url}/api/vi/multiple/multiple-get`
      );
      if (response.data.success) {
        setUploadedData(response.data.data);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong in getting the MultipleImageMultipleImage data"
      );
    }
  };

  useEffect(() => {
    getAllImg();
  }, []);
  const handleDelete = async (id) => {
    try {
      const { data } = await axios.delete(
        `${mod.api_url}/api/vi/multiple/deleteSliderImage/${id}`
      );

      if (data.success) {
        toast.success(data.message);
        getAllImg();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("not deleted this slider");
    }
  };

  return (
    <>
      <Homepage />
      <Center>
        {" "}
        <div className="col-md-11" style={{ marginTop: 90 }}>
          <Text textAlign="center" fontSize="xx-large">
            Slider Banner
          </Text>
          <div className="row">
            <div className="col-md-6">
              <Input
                type="text"
                name="title"
                id="outlined-basic"
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter Title"
                color="black"
                border="1px solid black"
              />
            </div>
            <div className="col-md-6">
              <Input
                type="text"
                name="des"
                id="outlined-basic"
                label="Description"
                variant="outlined"
                value={des}
                onChange={(e) => setDes(e.target.value)}
                placeholder="Enter Discription"
                color="black"
                border="1px solid black"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                type="text"
                name="subTitle"
                id="outlined-basic"
                label="Subtitle"
                variant="outlined"
                value={subTitle}
                onChange={(e) => setSubTitle(e.target.value)}
                placeholder="Enter Sub Title"
                color="black"
                border="1px solid black"
                marginTop={1}
              />
            </div>
            <div className="col-md-6">
              <label className="">
                {photo ? photo.name : ""}
                <Input
                  type="file"
                  name="photo"
                  onChange={(e) => setPhoto(e.target.files[0])}
                  accept=".jpg, .jpeg, .png"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Select Image"
                  color="black"
                  border="1px solid black"
                  marginTop={1}
                />
              </label>
            </div>
            <div className="mb-3">
              {photo && (
                <div className="text-center">
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Product photo"
                    height={"200px"}
                    className="img img-responsive"
                  />
                </div>
              )}
            </div>
            <Button
              width="30%"
              style={{ marginTop: 10 }}
              onClick={handleUpload}
            >
              Upload Slider
            </Button>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th scope="col">Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">Subtitle</th>
                    <th scope="col">Description</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedData &&
                    uploadedData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <img
                            src={`${mod.api_url}/api/vi/multiple/sliderImageII/${item._id}`}
                            alt="Uploaded"
                            style={{ width: 100 }}
                          />
                        </td>
                        <td>{item.title.substring(0, 70)}</td>
                        <td>{item.subTitle.substring(0, 50)}</td>
                        <td>{item.des.substring(0, 18)}</td>
                        <td>
                          <button
                            className="btn btn-danger ms-2 "
                            style={{ marginBottom: 2 }}
                            onClick={() => {
                              confirmAlert({
                                title: "Confirm yes or no",
                                message: "Are you sure to delete this?",
                                buttons: [
                                  {
                                    label: `Yes `,
                                    onClick: () => handleDelete(item._id),
                                  },
                                  {
                                    label: "No",
                                  },
                                ],
                              });
                            }}
                          >
                            <MdDeleteForever />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Center>
    </>
  );
};

export default MultiSlider;
